import type { Ref } from 'vue';
import {
  computed,
} from 'vue';

import type { VSwiperRef } from 'web/src/components/Swiper/VSwiper/types';
import type { CasinoCategoryListProps } from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryList/types';
import { useSwiperToActiveScroll } from 'web/src/components/Swiper/VSwiper/composables';

interface CasinoCategoryListComposable {
  swiper: Ref<VSwiperRef | undefined>;
}

export default function useCasinoCategoryList(
  props: CasinoCategoryListProps,
): CasinoCategoryListComposable {
  const selectedIndex = computed(() => {
    const index = props.list.findIndex((item) => item.isActive);
    return index > -1 ? index : 0;
  });

  const { swiper } = useSwiperToActiveScroll(selectedIndex);

  return {
    swiper,
  };
}

<script lang="ts" setup>
import { RouteName } from '@leon-hub/routing-config-names';

import CasinoLobbyBlockError from 'web/src/modules/casino/submodules/lobby/components/CasinoLobbyBlockError/CasinoLobbyBlockError.vue';
import CasinoLobbyNavigation from 'web/src/modules/casino/submodules/lobby/components/CasinoLobbyNavigation/CasinoLobbyNavigation.vue';
import { useCategoriesTabs } from 'web/src/modules/casino/submodules/lobby/composables';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import LobbyEgsNoGames from 'web/src/modules/lobby/components/LobbyEgsNoGames/LobbyEgsNoGames.vue';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';

import { useCasinoLobbyPage } from './composables';

const {
  isFavorite,
  hasGroup,
  groupName,
  isLoadingCategories,
  hasNoGames,
  isAllTab,
  groupId,
  groupUrl,
  categoryId,
  lobbyType,
  isLoadingLobby,
  skeletons,
  metaParameters,
  groupBlockType,
  removeFavoriteGames,
  onClickSearch,
  onClickGroup,
  onPlay,
} = useCasinoLobbyPage();

const {
  categoriesTabs,
} = useCategoriesTabs();

useSeoMetaParameters(metaParameters);

useKeepAlive();
</script>

<template>
  <div v-auto-id="'CasinoLobbyRoutePage'"
    v-data-test="{ el: 'casino-lobby-page', loading: isLoadingLobby }"
    :class="$style['casino-lobby']"
  >
    <CasinoLobbyNavigation
      v-if="!null"
      :active-group-name="groupName"
      :has-group-name="hasGroup"
      :categories-tabs="categoriesTabs"
      :is-categories-loading="isLoadingCategories"
      :class="$style['casino-lobby__navigation']"
      @click-search="onClickSearch"
      @click-groups="onClickGroup"
    />
    <CasinoLobbyBlockError
      v-if="groupBlockType"
      :group-name="groupName"
      :class="$style['casino-lobby__block-error']"
    />
    <LobbyEgsNoGames
      v-else-if="hasNoGames"
      :is-button-hidden="$route.name === RouteName.HOME"
    />
    <LobbyBlock
      :key="lobbyType"
      :lobby-type="lobbyType"
      :category-id="categoryId"
      prevent-items-fetch
      :is-filters-allowed="!isFavorite"
      :is-all-tab="isAllTab"
      :group-id="groupId"
      :group-url="groupUrl"
      :skeletons="skeletons"
      :no-background="!!null && isFavorite"
      :group-block="groupBlockType"
      is-full-height
      @game-click="onPlay"
      @delete-items="removeFavoriteGames"
    />
  </div>
</template>

<style lang="scss" module>
.casino-lobby {
  $self: &;

  &__navigation {
    padding: 8px 0;
  }

  &__no-games {
    width: 290px;
    padding: 40px 0 80px;
    margin: 0 auto;
  }

  &__block-error {
    margin: 0 auto;
  }
}
</style>

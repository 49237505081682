<script lang="ts" setup>
import { watch } from 'vue';
import { useRoute } from 'vue-router';

import type { CasinoCategoryListProps } from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryList/types';
import SwiperNavigationButtons
  from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import { useCasinoCategoryList } from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryList/composables';

const props = withDefaults(defineProps<CasinoCategoryListProps>(), {
  list: () => ([]),
});

const route = useRoute();

const {
  swiper,
} = useCasinoCategoryList(props);

watch(() => route.name, () => {
  swiper.value?.resetScrollPosition();
});
</script>

<template>
  <div v-auto-id="'CasinoCategoryList'" :class="$style['casino-category-list']">
    <VSwiper
      ref="swiper"
      is-scroll-snap-enabled
    >
      <VSwiperSlide
        v-for="item in list"
        :key="item.id"
        :class="$style['casino-category-list__slide']"
      >
        <slot
          name="item"
          :item="item"
        />
      </VSwiperSlide>
      <template
        v-if="'1'"
        #pagination-footer
      >
        <SwiperNavigationButtons />
      </template>
    </VSwiper>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .casino-category-list {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: left;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__slide {
      padding-left: 4px;

      &:first-child {
        padding-left: if($isDesktop, 0, 8px);
      }

      &:last-child {
        padding-right: if($isDesktop, 0, 8px);
      }
    }
  }
}
</style>

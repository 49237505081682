import type {
  RouteLocationNormalized,
} from 'vue-router';

import {
  HomePageType,
} from '@leon-hub/api-sdk';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';
import { AbstractPrefetch } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';

import useEgsBasePrefetch from 'web/src/modules/egs/composables/useEgsBasePrefetch';

export default class CasinoLobbyPagePrefetch extends AbstractPrefetch {
  private shouldRedirectToHome(route: RouteLocationNormalized): boolean {
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      return false;
    }

    const { homePageType } = useSiteConfigStore();

    return ((
      homePageType === HomePageType.LIVE_GAMES
      && route.name === CasinoRouteName.CASINO_LIVE_LOBBY
    ) || (
      homePageType === HomePageType.SLOTS
      && route.name === CasinoRouteName.CASINO_LOBBY
    ))
    && !route.params.groupUrl
    && !route.params.categoryUrl;
  }

  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    if (this.shouldRedirectToHome(to)) {
      return router.resolve301location({
        name: RouteName.HOME,
      });
    }

    return useEgsBasePrefetch().prefetch(to);
  }
}

import type {
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router';

import { HomePageType } from '@leon-hub/api-sdk';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter, SyncAbstractPrefetchResult } from '@core/router';
import { AbstractPrefetch } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';

export default class CasinoSlotsPrefetchOld extends AbstractPrefetch {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
  ): SyncAbstractPrefetchResult {
    const { homePageType } = useSiteConfigStore();

    let location: RouteLocationRaw = {
      name: CasinoRouteName.CASINO_LOBBY,
      params: to.params,
    };

    if ((homePageType === HomePageType.LIVE_GAMES || homePageType === HomePageType.SLOTS)
      && !to.params.groupUrl
      && !to.params.categoryUrl) {
      location = {
        name: RouteName.HOME,
      };
    }

    return router.resolve301location(location);
  }
}

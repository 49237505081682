<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import CasinoProviderSelect from 'web/src/modules/casino/submodules/lobby/components/CasinoProviderSelect/CasinoProviderSelect.vue';

import type { CasinoSidebarFilterBlockEmits, CasinoSidebarFilterBlockProps } from './types';

withDefaults(defineProps<CasinoSidebarFilterBlockProps>(), {});
const emit = defineEmits<CasinoSidebarFilterBlockEmits>();
function clickGroups(event: MouseEvent): void {
  emit('click-groups', event);
}

function clickSearch(event: MouseEvent): void {
  emit('click-search', event);
}

function clickReward(event: MouseEvent): void {
  emit('click-reward', event);
}

const iconSearchProperty: VIconProps = {
  name: IconName.SEARCH,
  size: IconSize.SIZE_20,
};
</script>

<template>
  <div v-auto-id="'CasinoSidebarFilterBlock'" :class="$style['casino-sidebar-filter-block']">
    <div
      v-data-test="{ el: 'casino-sidebar-search' }"
      :class="$style['casino-sidebar-filter-block__search']"
      @click="clickSearch"
    >
      <VIcon v-bind="iconSearchProperty" />
      <span>{{ $t('WEB2_CASINO_SIDEBAR_SEARCH') }}</span>
    </div>
    <CasinoProviderSelect
      :title="activeGroupName"
      :has-selected-group="hasGroupName"
      in-sidebar
      :class="$style['casino-sidebar-filter-block__provider']"
      @click="clickGroups"
    />
    <div
      v-if="isRewardCenterEnabled"
      :class="$style['casino-sidebar-filter-block__reward-center']"
      @click="clickReward"
    >
      <VIcon
        :name="IconName.BOLT"
        :size="IconSize.SIZE_20"
      />
      <span>{{ $t('WEB2_REWARD_CENTER_NAVIGATION') }}</span>
    </div>
  </div>
</template>

<style lang="scss" module>
.casino-sidebar-filter-block {
  padding: $casinoSidebarFilterBlockPadding;
  background-color: $casinoSidebarFilterBackgroundColor;
  border-radius: var(--BorderRadius);

  &__search {
    @include medium\14\16\025;

    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 44px;
    padding: 0 16px;
    margin-bottom: $casinoSidebarFilterSearchMarginBottom;
    color: var(--TextPrimary);
    cursor: pointer;
    background-color: $casinoSidebarFilterSearchBackgroundColor;
    border-radius: $casinoSidebarFilterSearchBackgroundRadius;

    &:hover {
      color: $casinoSidebarFilterSearchHoverColor;
      background-color: var(--Highlight);
    }
  }

  &__provider {
    height: 44px;
    padding: $casinoSidebarFilterProviderPadding;
    border-radius: $casinoSidebarFilterProviderRadius;
  }

  &__reward-center {
    @include casinoSidebarFilterFont;

    position: relative;
    display: flex;
    gap: 20px;
    align-items: center;
    height: 44px;
    padding: $casinoSidebarFilterPadding;
    color: $casinoSidebarFilterTextColor;
    cursor: pointer;
    border-radius: $casinoSidebarFilterRewardRadius;

    @include for-hover {
      &:hover {
        background: var(--Highlight);
      }
    }

    & span {
      width: 100%;
      max-width: 112px;
    }

    &:after {
      @include medium\12\16;

      position: absolute;
      top: calc(50% - 8px);
      right: 16px;
      display: block;
      height: 16px;
      padding: 0 4px;
      color: var(--DBrandText);
      content: 'New';
      background: linear-gradient(150deg, #892eff 12.02%, #5a3eff 88.34%);
      border-radius: 8px;
    }

    &-image {
      width: 24px;
      height: 24px;
    }
  }
}
</style>

<script lang="ts" setup>
import { ButtonKind, VButton } from '@components/buttons';

import CasinoSidebarBonusBlock from 'web/src/modules/casino/submodules/lobby/components/CasinoSidebarBonusBlock/CasinoSidebarBonusBlock.vue';
import CasinoSidebarFilterBlock from 'web/src/modules/casino/submodules/lobby/components/CasinoSidebarFilterBlock/CasinoSidebarFilterBlock.vue';
import CasinoSidebarNavigationBlock from 'web/src/modules/casino/submodules/lobby/components/CasinoSidebarNavigationBlock/CasinoSidebarNavigationBlock.vue';
import { useCasinoLobby, useCategoriesTabs } from 'web/src/modules/casino/submodules/lobby/composables';

const {
  groupName,
  hasGroup,
  onClickSearch,
  onClickGroup,
  onClickHelp,
  onClickReward,
  isLoadingCategories,
  isLoggedIn,
  isLoadingLobby,
  isHelpMode,
  isEgsLoyaltyEnabled,
  isRewardCenterEnabled,
} = useCasinoLobby();

const {
  categoriesTabs,
} = useCategoriesTabs();
</script>

<template>
  <div v-auto-id="'CasinoSidebarRouteComponent'" :class="$style['casino-sidebar']">
    <CasinoSidebarBonusBlock
      v-if="isLoggedIn && isEgsLoyaltyEnabled"
      :is-loading="isLoadingLobby"
    />
    <CasinoSidebarFilterBlock
      :active-group-name="groupName"
      :has-group-name="hasGroup"
      :is-reward-center-enabled="isRewardCenterEnabled"
      :class="$style['casino-sidebar__filter']"
      @click-groups="onClickGroup"
      @click-search="onClickSearch"
      @click-reward="onClickReward"
    />
    <CasinoSidebarNavigationBlock
      :class="$style['casino-sidebar__navigation']"
      :is-loading="isLoadingCategories"
      :list="categoriesTabs"
    />
    <div
      v-if="isHelpMode"
      :class="[
        $style['casino-sidebar__search'],
        $style['casino-sidebar__help'],
      ]"
    >
      <VButton
        :kind="ButtonKind.TRANSPARENT"
        :label="$t('WEB2_CASINO_SIDEBAR_HELP')"
        full-width
        @click="onClickHelp"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.casino-sidebar {
  display: flex;
  flex-direction: column;
  min-height: 400px;

  &__arrow-icon {
    color: var(--TextSecondary);
  }

  &__filter {
    margin-bottom: $casinoSidebarFilterMarginBottom;
  }

  &__search {
    background: var(--Layer1);
    border-radius: $casinoSidebarSearchRadius;

    &:hover {
      background-color: var(--Highlight);
    }
  }

  &__help {
    margin-top: 32px;
  }
}
</style>

<script lang="ts" setup>
import { VButton } from '@components/buttons';

import type { CasinoLobbyNavigationEmits, CasinoLobbyNavigationProps } from 'web/src/modules/casino/submodules/lobby/components/CasinoLobbyNavigation/types';
import CasinoCategoryTabs from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryTabs/CasinoCategoryTabs.vue';
import { useCasinoLobbyNavigation } from 'web/src/modules/casino/submodules/lobby/components/CasinoLobbyNavigation/composables';
import CasinoProviderSelect from 'web/src/modules/casino/submodules/lobby/components/CasinoProviderSelect/CasinoProviderSelect.vue';

defineProps<CasinoLobbyNavigationProps>();
const emits = defineEmits<CasinoLobbyNavigationEmits>();

const {
  searchButtonProperties,
  emitClickGroups,
  emitClickSearch,
} = useCasinoLobbyNavigation(emits);
</script>

<template>
  <div v-auto-id="'CasinoLobbyNavigation'" :class="$style['casino-lobby-navigation']">
    <div :class="$style['casino-lobby-navigation__group']">
      <VButton
        v-data-test="{ el: 'casino-button-search' }"
        v-bind="searchButtonProperties"
        :class="$style['casino-lobby-navigation__search']"
        @click="emitClickSearch"
      />
      <CasinoProviderSelect
        :title="activeGroupName"
        :has-selected-group="hasGroupName"
        :class="$style['casino-lobby-navigation__provider']"
        @click="emitClickGroups"
      />
    </div>
    <CasinoCategoryTabs
      :list="categoriesTabs"
      :is-loading="isCategoriesLoading"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .casino-lobby-navigation {
    display: flex;
    flex-direction: if($isDesktop, row, column);

    @media (min-width: 1150px) {
      display: none;
    }

    &__group {
      display: flex;
      margin: if($isDesktop, 0, 0 8px);
    }

    &__provider {
      margin: if($isDesktop, 0 16px 0 8px, 0 0 16px 8px);
    }

    &__search {
      @if $isDesktop {
        width: $casinoLobbyNavigationIconSize;
        height: $casinoLobbyNavigationIconSize;
        background-color: var(--Layer1);
      }
    }
  }
}
</style>
